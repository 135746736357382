import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../../attachements/images/png/geekDevs_logoV2NBackground_1000px_1000px.png";
import '../../styleSheets/components/NavBar.css';

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="icon" className="navbar-icon" />
        <span className="navbar-brand">geekDevs</span>
      </div>
      <ul className={`navbar-menu ${menuOpen ? 'open' : ''}`}>
        <li><Link to="/" onClick={() => setMenuOpen(false)}>Home</Link></li>
        <li><Link to="/services" onClick={() => setMenuOpen(false)}>Servicios</Link></li>
        <li><Link to="/courses" onClick={() => setMenuOpen(false)}>Cursos</Link></li>
        <li><Link to="/shop" onClick={() => setMenuOpen(false)}>Tienda</Link></li>
        <li><Link to="/contact" onClick={() => setMenuOpen(false)}>Contacto</Link></li>
        <li><Link to="/projects" onClick={() => setMenuOpen(false)}>Projects</Link></li>
      </ul>
      <div className="navbar-toggle" onClick={toggleMenu}>&#9776;</div>
    </nav>
  );
};

export default NavBar;