import React from 'react';
import '../../styleSheets/components/Footer.css'; // Importa el archivo CSS del footer

const Footer = () => {
    return (
        <footer className="footer">
            <p>COPYRIGHT © 2019. ALL RIGHTS RESERVED. DESIGNED BY geekDevs</p>
        </footer>
    );
};

export default Footer;