import React, { useState, useEffect } from 'react';
import { dbRealTime } from '../../firebase/firebaseConfig'; // Importamos nuestra instancia de la base de datos
import { ref, onValue, off } from 'firebase/database'; // Importamos las funciones necesarias
import CourseData from '../components/CourseData'; // Importamos el componente CourseData donde mostraremos cada curso

const CoursesList = () => {
  const [courses, setCourses] = useState([]); // Estado para almacenar la lista de cursos

  useEffect(() => {
    const dbRef = ref(dbRealTime, 'projects/proj_gyhJqHv7L4/data/courseData'); // Referencia a la colección de cursos en la base de datos

    // Función para obtener los datos y actualizar el estado
    const fetchData = (snapshot) => {
      const coursesData = snapshot.val(); // Obtenemos los datos del snapshot
      if (coursesData) {
        const coursesArray = Object.keys(coursesData).map(key => ({
          id: key,
          ...coursesData[key]
        }));
        setCourses(coursesArray); // Actualizamos el estado con los datos obtenidos
      } else {
        setCourses([]); // Si no hay datos, establecemos un array vacío
      }
    };

    // Manejo de errores al obtener datos
    const errorHandler = (error) => {
      console.error('Error fetching data:', error);
      // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje al usuario
    };

    // Escuchamos cambios en la base de datos
    onValue(dbRef, fetchData, errorHandler);

    // Función de limpieza al desmontar el componente para evitar memory leaks
    return () => {
      off(dbRef, fetchData); // Desactivamos la suscripción a cambios
    };
  }, []); // El segundo argumento [] indica que el efecto se ejecuta solo una vez al montar el componente

  return (
    <div className="coursesList">
      {courses.map((course) => (
        <CourseData
          key={course.id}
          name={course.name}
          description={course.description}
          banner={course.banner}
        />
      ))}
    </div>
  );
};

export default CoursesList;