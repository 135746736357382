import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes , Route } from 'react-router-dom';
import NavBar from './components/components/NavBar';
import Footer from './components/components/Footer'; 
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import CoursesList from './components/pages/Courses';
import Shop from './components/pages/Shop';
import Contact from './components/pages/Contact';
import Projects from './components/pages/Projects';

function App() {
  return (
    <div className='mainContainer'>
    <Router>
    <NavBar/>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/services" element={<Services/>} />
      <Route path="/courses" element={<CoursesList/>} />
      <Route path="/shop" element={<Shop/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="/portfolio" element={<Projects/>} />
  </Routes>
  <Footer />
</Router>
</div>
  );
}

export default App;