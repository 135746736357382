import React from 'react';
import '../../styleSheets/pages/Home.css'; // Importa el archivo CSS
import imagen from '../../attachements/images/png/mockupsApps.png'; // Importa la imagen (asegúrate de tener la imagen en tu proyecto)

const Home = () => {
    return (
        <div className="home-container">
            <div className="content">
                <div className="text-container">
                    <p className="subtitle">Innovación a tu medida</p>
                    <h1 className="title">Innovación tecnológica que potencia tu negocio</h1>
                    <p className="description">Implementa nuevas herramientas ágiles y adaptables que potenciarán tu negocio.</p>
                    <button className="learn-more-btn">Conócenos</button>
                </div>
                <div className="image-container">
                    <img src={imagen} alt="Imagen de ejemplo" className="feature-image" />
                </div>
            </div>
        </div>
    );
};

export default Home;