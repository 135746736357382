import React from 'react';
import '../../styleSheets/components/CourseData.css';

const CoursesData = (props) => {
  return (
    <div className="containerCourses">
      <img 
        className="imageBanner" 
        src={props.banner} 
        alt="Course banner" 
      />
      <div className="containerCoursesData">
        <p className="nameCourse">{props.name}</p>
        <p className="descriptionCourse">{props.description}</p>
        <button className='moreDetails'>More datails</button>
      </div>
    </div>
  );
};

export default CoursesData;