// Importa las funciones que necesitas de los SDK que necesitas
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getDatabase } from 'firebase/database';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA6rMP81ERdpbWMqf-RIZLp3p2IkFY3HjQ",
  authDomain: "geekdevs-9841e.firebaseapp.com",
  databaseURL: "https://geekdevs-9841e-default-rtdb.firebaseio.com",
  projectId: "geekdevs-9841e",
  storageBucket: "geekdevs-9841e.appspot.com",
  messagingSenderId: "746378129636",
  appId: "1:746378129636:web:4aa2ee271cd4b15e01fcf1",
  measurementId: "G-J7ZTNEC5FV"
};

// Inicializa la aplicación Firebase
const app = initializeApp(firebaseConfig);

// Activa Analytics si lo necesitas
//const analytics = getAnalytics(app);

// Obtiene una instancia de la base de datos en tiempo real
export const dbRealTime = getDatabase(app);

//
export const auth= getAuth(app)