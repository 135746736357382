// pages/Shop.jsx
import React from 'react';
import "../../styleSheets/pages/Courses.css"
import CoursesDataList from '../addCollection/CoursesDataList';

const CoursesList = () => {
  return (
    <div className='courses'>
      <CoursesDataList/>
    </div>
  );
};

export default CoursesList;