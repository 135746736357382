// pages/Shop.jsx
import React from 'react';

const Shop = () => {
  return (
    <div>
      <h1>Shop</h1>
      <p>Welcome to geekDevs!</p>
    </div>
  );
};

export default Shop;